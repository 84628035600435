<template>
  <a-row :gutter="24" style="margin-right: 0;margin-left: 0;background-color: #fff;">
    <a-col :md="5" :sm="24" style="padding: 0 0 0 0; border-right: 1px solid #eee;height: calc(92vh - 2px);">
      <a-card :bordered="false" :loading="treeLoading">
        <div v-if="this.orgTree != ''">
          <a-tree
            :treeData="orgTree"
            v-if="orgTree.length"
            @select="handleClick"
            :defaultExpandAll="true"
            :defaultExpandedKeys="defaultExpandedKeys"
            :replaceFields="replaceFields"
          />
        </div>
        <div v-else>
          <a-empty :image="simpleImage" />
        </div>
      </a-card>
    </a-col>
    <a-col :md="19" :sm="24">
      <x-card v-if="hasPerm('sysUser:page1')">
        <div slot="content" class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="关键词">
                  <a-input v-model="queryParam.searchValue" allow-clear placeholder="请输入姓名、账号、手机号" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="状态">
                  <a-select v-model="queryParam.searchStatus" allow-clear placeholder="请选择状态" default-value="0">
                    <a-select-option v-for="(item, index) in statusDictTypeDropDown" :key="index" :value="item.code">
                      {{ item.value }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </x-card>

      <div class="table-padding">
        <a-card :bordered="false">
          <s-table
            bordered
            ref="table"
            :columns="columns"
            :data="loadData"
            :alert="true"
            :rowKey="record => record.id"
          >
            <template slot="operator">
              <a-button type="primary" v-if="hasPerm('sysUser:add1')" icon="plus" @click="$refs.addForm.add()"
                >新增用户
              </a-button>
              <a-upload
                :customRequest="customRequest"
                :multiple="true"
                :showUploadList="false"
                name="file"
                v-if="hasPerm('sysUser:import1')"
              >
                <a-button icon="up-circle">导入</a-button>
              </a-upload>
              <a-button v-if="hasPerm('sysUser:export1')" icon="down-circle" @click="sysUserExport()">导出 </a-button>
            </template>
            <s-column title="序号" dataIndex="index" align="center" :width="55">
              <template slot="customRender" slot-scope="text, record, index">
                {{ index + 1 }}
              </template>
            </s-column>
            <span slot="sex" slot-scope="text">
              {{ sexFilter(text) }}
            </span>
            <span slot="status" slot-scope="text, record" v-if="hasPerm('sysUser:changeStatus1')">
              <a-popconfirm
                placement="top"
                :title="text === 0 ? '确定停用该用户？' : '确定启用该用户？'"
                @confirm="() => editUserStatus(text, record)"
              >
                <a>{{ statusFilter(text) }}</a>
              </a-popconfirm>
            </span>
            <span slot="status" v-else>
              {{ statusFilter(text) }}
            </span>
            <span slot="action" slot-scope="text, record">
              <a v-if="hasPerm('sysUser:edit1')" @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" v-if="hasPerm('sysUser:edit1')" />
              <a-dropdown
                v-if="
                  hasPerm('sysUser:resetPwd1') ||
                    hasPerm('sysUser:grantRole1') ||
                    hasPerm('sysUser:grantData1') ||
                    hasPerm('sysUser:delete1')
                "
              >
                <a class="ant-dropdown-link">
                  更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-if="hasPerm('sysUser:resetPwd1')">
                    <a-popconfirm placement="topRight" title="确认重置密码？" @confirm="() => resetPwd(record)">
                      <a>重置密码</a>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item v-if="hasPerm('sysUser:grantRole1')">
                    <a @click="$refs.userRoleForm.userRole(record)">授权角色</a>
                  </a-menu-item>
                  <a-menu-item v-if="hasPerm('sysUser:grantData1')">
                    <a @click="$refs.userOrgForm.userOrg(record)">授权数据</a>
                  </a-menu-item>
                  <a-menu-item v-if="hasPerm('sysUser:delete1')">
                    <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysUserDelete(record)">
                      <a>删除</a>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </s-table>
          <add-form ref="addForm" @ok="handleOk" />
          <edit-form ref="editForm" @ok="handleOk" />
          <user-role-form ref="userRoleForm" @ok="handleOk" />
          <user-org-form ref="userOrgForm" @ok="handleOk" />
        </a-card>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { STable, XCard } from '@/components'
import { Empty } from 'ant-design-vue'
import { getOrgTree } from '@/api/modular/system/orgManage'
import {
  getUserPage,
  sysUserDelete,
  sysUserChangeStatus,
  sysUserResetPwd,
  sysUserExport,
  sysUserImport
} from '@/api/modular/system/userManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
import userRoleForm from './userRoleForm'
import userOrgForm from './userOrgForm'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm,
    userRoleForm,
    userOrgForm
  },
  data() {
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 70,
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '账号',
          dataIndex: 'account'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: {
            customRender: 'sex'
          }
        },
        {
          title: '手机',
          dataIndex: 'phone'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: {
            customRender: 'status'
          }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return getUserPage(Object.assign(parameter, this.queryParam)).then(res => {
          return res.data
        })
      },
      orgTree: [],
      selectedRowKeys: [],
      selectedRows: [],
      defaultExpandedKeys: [],
      sexDictTypeDropDown: [],
      statusDictTypeDropDown: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        key: 'id'
      }
    }
  },
  created() {
    /**
     * 获取到机构树，展开顶级下树节点，考虑到后期数据量变大，不建议全部展开
     */
    getOrgTree(Object.assign(this.queryParam)).then(res => {
      this.treeLoading = false
      if (!res.success) {
        return
      }
      this.orgTree = res.data
      for (var item of res.data) {
        // eslint-disable-next-line eqeqeq
        if (item.parentId == 0) {
          this.defaultExpandedKeys.push(item.id)
        }
      }
    })
    this.sysDictTypeDropDown()
    if (
      this.hasPerm('sysUser:edit1') ||
      this.hasPerm('sysUser:resetPwd1') ||
      this.hasPerm('sysUser:grantRole1') ||
      this.hasPerm('sysUser:grantData1') ||
      this.hasPerm('sysUser:delete1')
    ) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: {
          customRender: 'action'
        }
      })
    }
  },
  methods: {
    sexFilter(sex) {
      // eslint-disable-next-line eqeqeq
      const values = this.sexDictTypeDropDown.filter(item => item.code == sex)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter(status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDictTypeDropDown.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({
        code: 'sex'
      }).then(res => {
        this.sexDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({
        code: 'common_status'
      }).then(res => {
        this.statusDictTypeDropDown = res.data
      })
    },
    /**
     * 修改用户状态
     */
    editUserStatus(code, record) {
      // eslint-disable-next-line no-unused-vars
      const status = 0
      // eslint-disable-next-line eqeqeq
      if (code == 0) {
        this.status = 1
        // eslint-disable-next-line eqeqeq
      } else if (code == 1) {
        this.status = 0
      }
      sysUserChangeStatus({
        id: record.id,
        status: this.status
      }).then(res => {
        if (res.success) {
          this.$message.success('操作成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('操作失败：' + res.message)
        }
      })
    },
    /**
     * 重置密码
     */
    resetPwd(record) {
      sysUserResetPwd({
        id: record.id
      }).then(res => {
        if (res.success) {
          this.$message.success('重置成功')
          // this.$refs.table.refresh()
        } else {
          this.$message.error('重置失败：' + res.message)
        }
      })
    },
    /**
     * 删除用户
     * @param record
     */
    sysUserDelete(record) {
      sysUserDelete(record)
        .then(res => {
          if (res.success) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('删除失败：' + res.message)
          }
        })
        .catch(err => {
          this.$message.error('删除错误：' + err.message)
        })
    },
    // 导出用户文件
    sysUserExport(e) {
      this.cardLoading = true
      sysUserExport()
        .then(res => {
          this.cardLoading = false
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch(err => {
          this.cardLoading = false
          this.$message.error('下载错误：获取文件流错误')
        })
    },
    downloadfile(res) {
      var blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8'
      })
      var contentDisposition = res.headers['content-disposition']
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(contentDisposition)
      const filename = matches[1].replace(/['"]/g, '')
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) // 创建下载的链接
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    },
    /**
     * 上传用户文件
     */
    customRequest(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      sysUserImport(formData).then(res => {
        if (res.success) {
          this.$message.success('上传成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('上传失败：' + res.message)
        }
      })
    },
    /**
     * 点击左侧机构树查询列表
     */
    handleClick(e) {
      this.queryParam = {
        'sysEmpParam.orgId': e.toString()
      }
      this.$refs.table.refresh(true)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
<style lang="less">
.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}
</style>
